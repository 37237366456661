const fallbackGoogleAnalyticsConfig = {
  dataLayerName: 'ocConnectWidgetTracker',
  gtmId: 'GTM-PK78SWB'
};

export class Analytics {
  gtmId;
  dataLayerName;

  constructor(
    gtmId = fallbackGoogleAnalyticsConfig.gtmId,
    dataLayerName = fallbackGoogleAnalyticsConfig.dataLayerName
  ) {
    this.gtmId = gtmId;
    this.dataLayerName = dataLayerName;
  }

  getDataLayer() {
    return window?.[this.dataLayerName];
  }

  trackEvent(event) {
    if (typeof window === 'undefined' || !window?.[this.dataLayerName]) {
      return;
    }

    window?.[this.dataLayerName]?.push(event);
  }

  integrateAnalytics() {
    if (typeof window === 'undefined' || window?.[this.dataLayerName]) {
      return;
    }

    const { dataLayerName, gtmId } = this;

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', dataLayerName, gtmId);
  }
}
